import React, { useCallback, useEffect, useState } from 'react'
import { PassportType } from '../types'
import { Button, CustomImage } from '../components/elements'
import imageDoorDefault from '../assets/images/door-default.svg'
import imageDefault from '../assets/images/passport-default.jpg'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Pagination } from 'swiper/modules'
import { type Swiper as SwiperType } from 'swiper/types'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/pagination'
import { IconCheck, IconKey } from '../components/icons'
import { useTranslation } from 'react-i18next'
import { ErrorModal, LoadingModal, SuccessModal } from '../components/modal'
import LinesEllipsis from 'react-lines-ellipsis'
import { Layout, Notification } from '../components/common'
import { useSetRecoilState } from 'recoil'
import { intercomErrorState } from '../store/error'

const apiUrl = process.env.REACT_APP_API_URL

type DoorType = {
  id: string
  state: 'success' | 'error' | 'initial'
}

const PassportScreen: React.FC<PassportType> = ({
  uuid,
  imageSmall,
  imageMedium,
  imageLarge,
  devices,
}) => {
  const [swiperRef, setSwiperRef] = useState<SwiperType | null>(null)
  const setGlobalError = useSetRecoilState(intercomErrorState)
  const { t } = useTranslation()
  const [buttons, setButtons] = useState<DoorType[]>(
    devices.map((device) => ({ id: device.Id, state: 'initial' }))
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const setButtonState = (id: string, state: 'success' | 'error') => {
    const newButtons = [...buttons]
    const current = newButtons.find((b) => b.id === id)
    if (current) {
      current.state = state
    }
    setButtons(newButtons)
  }
  useEffect(() => {
    let errorTimeout: string | number | NodeJS.Timeout | undefined,
      successTimeout: string | number | NodeJS.Timeout | undefined
    if (error) {
      errorTimeout = setTimeout(() => {
        setError(false)
      }, 4000)
    }
    if (success) {
      successTimeout = setTimeout(() => {
        swiperRef.slideNext()
        setSuccess(false)
      }, 4000)
    }
    return () => {
      clearTimeout(errorTimeout)
      clearTimeout(successTimeout)
    }
  }, [error, loading, success])
  const settings = {
    slidesPerView: 1,
    spaceBetween: 15,
    freeMode: true,
    pagination: { clickable: true },
    modules: [FreeMode, Pagination],
  }
  const open = useCallback((id: string) => {
    setLoading(true)
    fetch(`${apiUrl}api/visitor/${uuid}/device/${id}/unlock`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(async (res) => {
        const data = await res.json()
        if (res.ok) {
          setButtonState(id, 'success')
          setSuccess(true)
        } else if (data.type === 'OperationCannotBePerformed') {
          setGlobalError(t('pass.expired'))
        } else {
          setButtonState(id, 'error')
          setError(true)
        }
      })
      .catch(() => {
        setButtonState(id, 'error')
        setError(true)
      })
      .finally(() => setLoading(false))
  }, [])
  return (
    <>
      <Layout classes="justify-between bg-gray-light">
        <div className="w-full max-h-[40%] grow relative overflow-hidden shrink-0">
          <CustomImage
            classes="absolute left-0 top-0 right-0 bottom-0"
            src={imageMedium ?? imageLarge ?? imageSmall}
            defaultSrc={imageDefault}
            alt="passport image"
          />
          <span className="absolute top-[30%] left-6 text-white font-bold text-2xl">
            {t('welcome.passport')}
          </span>
          <div className="absolute top-full left-0 -right-4 bg-gray-light h-24 -rotate-6 origin-top-left"></div>
        </div>
        <div className="w-[70%] max-w-[300px] pt-6 pb-12 mx-auto">
          <Swiper {...settings} className="overflow-visible" onSwiper={setSwiperRef}>
            {devices.map((device) => (
              <SwiperSlide key={device.Id}>
                <div key={device} className="px-3">
                  <div className="w-full relative overflow-hidden rounded-t-md pt-[100%]">
                    {buttons.find((b) => b.id === device.Id)?.state === 'error' && (
                      <Notification
                        type="error"
                        text={t('pass.error')}
                        small
                        classes="absolute left-4 right-4 z-50"
                      />
                    )}
                    <CustomImage
                      src={device.MediumImageUrl ?? device.LargeImageUrl ?? device.SmallImageUrl}
                      defaultSrc={imageDoorDefault}
                      alt="the Door"
                      classes="absolute left-0 top-0 right-0 bottom-0"
                    />
                  </div>
                  <div className="flex justify-between items-end bg-white rounded-md -mt-2 relative p-5">
                    <div className="text-xs leading-3">
                      <h3 className="block overflow-hidden max-h-[40px] text-lg leading-5 font-medium mb-2">
                        <LinesEllipsis
                          text={device.Name}
                          maxLine="2"
                          ellipsis="..."
                          trimRight
                          basedOn="letters"
                        />
                      </h3>
                      <span className="block overflow-hidden max-h-[25px]">
                        <LinesEllipsis
                          text={device.LocationName}
                          maxLine="2"
                          ellipsis="..."
                          trimRight
                          basedOn="letters"
                        />
                      </span>
                    </div>
                    <Button
                      icon={
                        buttons.find((b) => b.id === device.Id)?.state === 'success' ? (
                          <IconCheck size={46} />
                        ) : (
                          <IconKey size={46} />
                        )
                      }
                      onClick={() => open(device.Id)}
                      classes={`w-20 h-20 shrink-0 ml-2 ${buttons.find((b) => b.id === device.Id)?.state === 'success' ? 'bg-access text-access-secondary' : 'bg-access-secondary text-access'}`}
                    />
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Layout>
      {loading && <LoadingModal />}
      {success && <SuccessModal text={t('opened')} />}
      {error && <ErrorModal text={t('error.fail')} />}
    </>
  )
}

export default PassportScreen
