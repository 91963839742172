import React, { FC, ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import Modal, { Styles } from 'react-modal'
import imageDialPad from '../../assets/images/baseline_dialpad_black_36dp.png'
import imageLogo from '../../assets/images/zesec-dark.png'
import { accessTokenState, callDataState } from '../../store/call'
import { intercomCompanyState } from '../../store/data'
import { CustomImage, Footer } from './index'
import { IconArrowRight, IconClose } from '../icons'
import { intercomErrorState } from '../../store/error'
import { ContentModal, ErrorModal } from '../modal'
import { modalStyles } from '../modal'
import { Player } from '@lottiefiles/react-lottie-player'

const apiUrl = process.env.REACT_APP_API_URL

const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
const modalDelay = 4000

const DialPad: FC = () => {
  const { t } = useTranslation()
  const [modalContent, setModalContent] = useState<ReactNode | null>(null)
  const [modalError, setModalError] = useState<string | null>(null)
  const [modalDialIsOpen, setModalDialIsOpen] = useState(false)
  const [dialCode, setDialCode] = useState<string>('')
  const callData = useRecoilValue(callDataState)
  const accessToken = useRecoilValue(accessTokenState)
  const { imageMedium } = useRecoilValue(intercomCompanyState)
  const setError = useSetRecoilState(intercomErrorState)
  const openDialModal = () => {
    setModalDialIsOpen(true)
  }
  const closeDialModal = useCallback((e: any) => {
    e?.stopPropagation()
    setModalDialIsOpen(false)
  }, [])
  const openModalErrorFail = () => {
    setModalError(t('error.fail'))
  }
  const openModalErrorCode = () => {
    setModalError(t('error.code'))
  }
  const openModalLoading = () => {
    setModalContent(
      <>
        <div className="w-56 h-56 relative m-auto mt-6">
          <div className="w-56 h-56 absolute left-0 top-0 right-0 bottom-0 m-auto border-8 border-white border-t-blue rounded-full animate-spin" />
          <div className="w-36 h-36 absolute left-0 top-0 right-0 bottom-0 m-auto z-10 rounded-full overflow-hidden">
            <CustomImage src={imageMedium} alt="Loading" defaultSrc={imageLogo} />
          </div>
        </div>
        <div className="font-bold my-4 mx-6 text-center">{t('connecting')}</div>
      </>
    )
  }
  const openModalSuccess = () => {
    setModalContent(
      <div>
        <div className="mt-8">
          <Player
            autoplay
            keepLastFrame
            speed={0.8}
            src="https://lottie.host/6dd43c66-36c9-470c-9656-27c36e65de75/eVFhphaoEb.json"
            style={{ height: '200px', width: '200px' }}
          />
        </div>
        <div className="font-bold my-4 mx-6 text-center">{t('opened')}</div>
      </div>
    )
  }
  const closeModalError = () => {
    setModalError(null)
  }
  const closeModalContent = () => {
    setModalContent(null)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const modalTimeout = useRef<any>(null)

  const setModalTimeout = (closer: () => void) => {
    modalTimeout.current = setTimeout(() => {
      closer()
    }, modalDelay)
  }

  useEffect(() => {
    setDialCode('')
  }, [modalDialIsOpen])

  useEffect(() => {
    if (dialCode.length === 4 && callData && accessToken) {
      const code = dialCode
      closeDialModal(null)
      openModalLoading()
      fetch(`${apiUrl}api/device/${callData.deviceId}/doorcode/${code}/unlock`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          Accept: 'application/json',
        },
      })
        .then((response) => response.json())
        .then((json) => {
          if (json.result === 'Unlocked') {
            openModalSuccess()
            setModalTimeout(closeModalContent)
          } else {
            closeModalContent()
            json?.error === 'Invalid Doorcode' ? openModalErrorCode() : openModalErrorFail()
            setModalTimeout(closeModalError)
          }
        })
        .catch(() => {
          setError(t('error.fail'))
        })
    }
    return () => {
      clearTimeout(modalTimeout.current)
    }
  }, [dialCode])

  return (
    <>
      <div
        className="flex items-center shadow-lg bg-white rounded-2xl p-4 mt-5"
        onClick={openDialModal}
      >
        <div className="w-8 h-8 mr-4">
          <CustomImage src={imageDialPad} alt="Dial pad" />
        </div>
        <span className="grow">{t('doorCode')}</span>
        <IconArrowRight />
      </div>
      {modalDialIsOpen && (
        <Modal
          isOpen={modalDialIsOpen}
          onRequestClose={closeDialModal}
          style={modalStyles as Styles}
          shouldCloseOnOverlayClick={false}
          shouldCloseOnEsc={false}
        >
          <div className="absolute right-4 top-4" onClick={closeDialModal}>
            <IconClose />
          </div>
          <div className="font-bold my-4 mx-6 text-center">{t('enterCode')}</div>
          <div className="flex flex-wrap justify-center items-center">
            {numbers.map((number) => (
              <button
                key={number}
                className="w-14 h-14 rounded-full bg-gray-medium m-2 active:bg-gray-dark"
                onClick={() => setDialCode(dialCode + number)}
              >
                <span className="text-2xl font-bold relative left-[1px] top-[2px]">{number}</span>
              </button>
            ))}
          </div>
          <Footer />
        </Modal>
      )}
      {!!modalError && <ErrorModal text={modalError} />}
      {!!modalContent && <ContentModal>{modalContent}</ContentModal>}
    </>
  )
}

export default DialPad
