import { atom, RecoilState } from 'recoil'
import { LocationType, PersonType } from '../types'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const intercomLocationState: RecoilState<LocationType | null> = atom({
  key: 'intercom_location',
  default: null,
})

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const intercomListState: RecoilState<PersonType[] | null> = atom({
  key: 'intercom_list',
  default: null,
})

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const intercomDoorState: RecoilState<DoorType | null> = atom({
  key: 'intercom_door',
  default: null,
})

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const intercomCompanyState: RecoilState<CompanyType | null> = atom({
  key: 'intercom_company',
  default: null,
})
