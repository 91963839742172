import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import sv from './sv'
import en from './en'
import { devConsoleLog } from '../utils'

const resources = {
  en,
  sv,
}

const getLanguage = () => {
  switch (navigator.language) {
    case 'sv':
    case 'sv-SE':
      return 'sv'
    default:
      return 'en'
  }
}

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: getLanguage(),
    interpolation: {
      escapeValue: false,
    },
  })
  .catch((e) => devConsoleLog(e))

export default i18n
