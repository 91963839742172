import React from 'react'
import LinesEllipsis from 'react-lines-ellipsis'
import { ButtonFullProps } from '../index'

const ButtonFull: React.FC<ButtonFullProps> = ({ text, color, classes = '', icon, onClick }) => {
  return (
    <button
      className={`flex items-center justify-between w-full font-regular px-6 p-4 shadow bg-white rounded-2xl ${classes}`}
      onClick={onClick}
    >
      <span style={{ color: color.text }}>
        <LinesEllipsis text={text} maxLine="2" ellipsis="..." trimRight basedOn="letters" />
      </span>
      <span style={{ color: color.icon }}>{icon}</span>
    </button>
  )
}

export default ButtonFull
