import { atom, RecoilState } from 'recoil'
import { Screen } from '../types'

type ScreenStateType = {
  screen: Screen
  props?: { [key: string]: any }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const screenState: RecoilState<ScreenStateType> = atom({
  key: 'current_screen',
  default: { screen: Screen.START },
})
