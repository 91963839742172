import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from 'react'
import dialMP3 from '../assets/audio/dial.mp3'
import Centrifuge from 'centrifuge'
import { devConsoleLog, isWebRTCSupported, parseJwt } from '../utils'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { accessTokenState, callDataState } from '../store/call'
import { intercomErrorState } from '../store/error'
import { useTranslation } from 'react-i18next'
import { CallStatus } from '../types'
import { usePrevious } from './index'
import { useCookies } from 'react-cookie'
import { NotificationProps } from '../components/common'

const apiUrl = process.env.REACT_APP_API_URL
const DOMAIN = process.env.REACT_APP_DOMAIN

type CallType = {
  activeCamera: number
  audioDevices: MediaDeviceInfo[]
  callClosed: boolean
  callId: string
  camOn: number
  centrifuge?: Centrifuge
  dialSound: HTMLAudioElement
  dialSoundLoop?: ReturnType<typeof setInterval>
  iceStarted: boolean
  intercomChannel: string
  localStream?: MediaStream
  micOn: number
  rtcConnection?: RTCPeerConnection
  videoDevices: MediaDeviceInfo[]
}

const rtcConfig = {
  iceCandidatePoolSize: 4,
  iceServers: [
    { urls: 'stun:stun.l.google.com:19305' },
    { urls: 'stun:stun.l.google.com:19302' },
    {
      urls: 'turn:turn.zesec.com:3478',
      username: 'zesec',
      credential: 'Theipaeme1Kethae2que',
    },
  ],
}

const useCall = ({
  targetFrame,
  userId,
  userFrame,
  setCallStatus,
  setTargetFrameMode,
  showNotification,
}: {
  targetFrame: MutableRefObject<HTMLVideoElement | null>
  userId?: number
  userFrame: MutableRefObject<HTMLVideoElement | null>
  setCallStatus: Dispatch<SetStateAction<CallStatus>>
  setTargetFrameMode: (value: boolean) => void
  showNotification: (value: NotificationProps) => void
}) => {
  const [status, setStatus] = useState<CallStatus>(CallStatus.INITIAL)
  const token = useRecoilValue(accessTokenState)
  const callData = useRecoilValue(callDataState)
  const setError = useSetRecoilState(intercomErrorState)
  const { t } = useTranslation()
  const [cookies, setCookies, removeCookie] = useCookies()
  const [notification, setNotification] = useState<NotificationProps | null>(null)

  const prevStatus = usePrevious(status)

  const callTimer = useRef<any>(null)

  const call = useRef<CallType>({
    activeCamera: 0,
    audioDevices: [],
    callClosed: false,
    callId: '',
    camOn: 1,
    centrifuge: undefined,
    dialSound: new Audio(dialMP3),
    dialSoundLoop: undefined,
    iceStarted: false,
    intercomChannel: '',
    localStream: undefined,
    micOn: 1,
    rtcConnection: undefined,
    videoDevices: [],
  })

  const showPassError = (e: any, id: string) => {
    devConsoleLog(e)
    setNotification({ id, text: e, type: 'error' })
  }

  const startDialSound = () => {
    call.current.dialSound
      .play()
      .then(() => {
        call.current.dialSoundLoop = setInterval(() => {
          call.current.dialSound.play().catch((e) => {
            devConsoleLog('loop play error', e)
          })
        }, 4000)
      })
      .catch((e) => {
        devConsoleLog('initial dial play error', e)
      })
  }

  const stopDialSound = () => {
    if (call.current.dialSoundLoop) {
      call.current.dialSound.pause()
      clearInterval(call.current.dialSoundLoop)
    }
  }

  const getVideoConstraints = () => {
    if (call.current.videoDevices.length === 1) {
      return { video: true, audio: true }
    }
    if (call.current.videoDevices.length > 1) {
      return {
        video: {
          deviceId: { exact: call.current.videoDevices[call.current.activeCamera].deviceId },
          width: {
            min: 640,
            max: 1280,
          },
        },
        audio: { deviceId: { exact: call.current.audioDevices[0].deviceId } },
      }
    }

    return { video: true, audio: true }
  }

  const stopStream = () => {
    try {
      stopDialSound()
      clearTimeout(callTimer.current)
      call.current.iceStarted = false
      call.current.callClosed = true
      if (call.current.localStream) {
        // @ts-expect-error exists method
        if (call.current.rtcConnection?.removeStream) {
          call.current.localStream.getTracks().forEach((t) => t.stop())
          // @ts-expect-error exists method
          call.current.rtcConnection?.removeStream(call.current.localStream)
        } else {
          call.current.localStream.getTracks().forEach((track) => {
            call.current.localStream?.removeTrack(track)
            const sender = call.current.rtcConnection
              ?.getSenders()
              ?.find((sender) => sender.track === track)
            if (sender) {
              call.current.rtcConnection?.removeTrack(sender)
            }
          })
        }
      }
      call.current.localStream = undefined
      if (userFrame.current) {
        userFrame.current.srcObject = null
      }
      if (targetFrame.current) {
        targetFrame.current.srcObject = null
      }
    } catch (e: any) {
      devConsoleLog(e)
    } finally {
      resetCall()
    }
  }

  const stopCall = () => {
    stopDialSound()
    fetch(`${apiUrl}api/intercom/caller/hangup`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        UUID: call.current.callId,
      }),
    })
      .catch((e) => {
        devConsoleLog('cancelCallRequest error', e)
      })
      .finally(() => {
        stopStream()
      })
  }

  const centrifugeInit = (channel: string) => {
    call.current.centrifuge?.subscribe(channel, (message) => {
      const centrifugeUserId = token ? parseJwt(token).sub : null
      if (centrifugeUserId === message.info?.user) {
        return
      }
      const type = message.data?.type ?? message.data?.Type
      switch (type.toLowerCase()) {
        case 'ready':
          devConsoleLog('message', 'ready')
          devConsoleLog('getVideoConstraints', JSON.stringify(getVideoConstraints()))
          navigator.mediaDevices
            .getUserMedia(getVideoConstraints())
            .then((stream) => {
              call.current.localStream = stream
              call.current.localStream.getAudioTracks()[0].enabled
              // @ts-expect-error exists method
              if (call.current.rtcConnection?.addStream) {
                // @ts-expect-error exists method
                call.current.rtcConnection?.addStream(call.current.localStream)
                devConsoleLog('centrifugeInit addStream')
              } else {
                stream.getTracks().forEach((track) => {
                  const sender = call.current.rtcConnection?.addTrack(track, stream)
                  devConsoleLog('centrifugeInit no addStream', sender)
                })
                devConsoleLog('centrifugeInit no addStream')
              }
              devConsoleLog('centrifugeInit rtcConnection', call.current.rtcConnection)
              devConsoleLog('centrifugeInit userFrame', userFrame.current)
              if (userFrame.current) {
                userFrame.current.srcObject = call.current.localStream
                userFrame.current.volume = 1.0
              }
            })
            .catch((e) => {
              stopDialSound()
              setError(e)
            })
          break

        case 'offer':
          devConsoleLog('message', 'offer')
          call.current.rtcConnection
            ?.setRemoteDescription(new RTCSessionDescription(message.data.sdp))
            .then(() => {
              call.current.rtcConnection
                ?.createAnswer()
                .then((answer) => {
                  call.current.rtcConnection
                    ?.setLocalDescription(answer)
                    .then(() => {
                      call.current.centrifuge?.publish(call.current.intercomChannel, {
                        type: 'answer',
                        sdp: answer,
                      })
                    })
                    .catch((e) => {
                      devConsoleLog('answer create ld set error', e)
                    })
                })
                .catch((e) => {
                  devConsoleLog('answer create error', e)
                })
            })
            .catch((e) => {
              devConsoleLog('answer send error', e)
            })
          break

        case 'pre_answer':
          devConsoleLog('pre_answer', 'ready')
          call.current.rtcConnection
            ?.setRemoteDescription(new RTCSessionDescription(message.data.sdp))
            .then(() => {
              call.current.iceStarted = true
            })
            .catch((e) => {
              devConsoleLog('answer got error', e)
            })
          break

        case 'accept_answer':
          devConsoleLog('message', 'accept_answer')
          stopDialSound()
          break

        case 'accepted':
          devConsoleLog('message', 'accepted')
          break

        case 'answer':
          devConsoleLog('message', 'answer')
          stopDialSound()
          setTargetFrameMode(true)
          call.current.rtcConnection
            ?.setRemoteDescription(new RTCSessionDescription(message.data.sdp))
            .then(() => {
              call.current.iceStarted = true
              callTimer.current = setTimeout(() => {
                stopCall()
              }, 60000)
            })
            .catch((e) => {
              devConsoleLog('answer got error', e)
            })
          break

        case 'rejected':
          devConsoleLog('message', 'rejected')
          if (!call.current.iceStarted) {
            stopCall()
            setStatus(CallStatus.NO_ANSWER)
          } else {
            stopDialSound()
            stopStream()
            setError('rejected')
          }
          break

        case 'busy':
          devConsoleLog('message', 'busy')
          stopDialSound()
          setStatus(CallStatus.BUSY)
          break

        case 'completed':
          devConsoleLog('message', 'completed')
          stopStream()
          setStatus(CallStatus.COMPLETED)
          break

        case 'timeout':
          devConsoleLog('message', 'timeout')
          stopDialSound()
          setStatus(CallStatus.NO_ANSWER)
          break

        case 'visitor': {
          const {
            visitor: { uuid, dateTo },
          } = message.data
          setTimeout(() => {
            fetch(`${apiUrl}api/visitor/public/${uuid}`, {
              method: 'GET',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
              },
            })
              .then((res) => res.json())
              .then((json) => {
                if (json.error) {
                  showPassError(t('pass.notDelivered'), uuid)
                  return
                }
                const {
                  Name: title,
                  SmallImageUrl: imageSmall,
                  MediumImageUrl: imageMedium,
                  LargeImageUrl: imageLarge,
                  Devices: devices,
                } = json.Passport
                const visitor = `${new Date().getTime()}__visitor__${uuid}`
                if (cookies[visitor]) {
                  removeCookie(visitor)
                  localStorage.removeItem(visitor)
                }
                const options = {
                  domain: DOMAIN,
                  expires: new Date(dateTo + 'Z'),
                  path: '/',
                }
                setCookies(visitor, uuid, options)
                localStorage.setItem(
                  visitor,
                  JSON.stringify({ title, imageSmall, imageMedium, imageLarge, devices })
                )
                setNotification({ id: uuid, text: t('pass.received'), type: 'info' })
              })
              .catch((e) => {
                showPassError(e, uuid)
              })
          }, 500)
          break
        }

        case 'candidate':
          devConsoleLog('message', 'candidate')
          call.current.rtcConnection
            ?.addIceCandidate(new RTCIceCandidate(message.data.sdp))
            .catch((e) => {
              devConsoleLog('remote candidate error', e)
            })
          break

        default:
          devConsoleLog('message', 'UNKNOWN', message.data)
      }
    })
  }

  const connect = async () => {
    const stream = await navigator.mediaDevices
      .getUserMedia({ audio: { echoCancellation: false }, video: true })
      .then((stream) => stream)
      .catch((e) => {
        stopDialSound()
        setError(t('error.camera'))
        devConsoleLog('getUserMedia error', e)
      })
    await navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        call.current.videoDevices = devices.filter((device) => device.kind === 'videoinput')
        call.current.audioDevices = devices.filter((device) => device.kind === 'audioinput')
        if (call.current.centrifuge && token && call.current.videoDevices.length >= 1) {
          call.current.centrifuge.setToken(token)
          call.current.centrifuge.connect()
        }
        stream?.getTracks().forEach((t) => t.stop())
      })
      .catch((e) => {
        stopDialSound()
        setError(e)
        devConsoleLog('centrifuge connect error', e)
      })
  }

  const startCall = async () => {
    if (!call.current.rtcConnection || !call.current.centrifuge) {
      initCall()
    }
    if (isWebRTCSupported()) {
      await connect()
    } else {
      stopDialSound()
      setError(t('error.video'))
      return
    }
    devConsoleLog('start', call.current.rtcConnection)
    if (
      !call.current.rtcConnection ||
      !call.current.centrifuge ||
      !targetFrame.current ||
      !userFrame.current ||
      !userId
    ) {
      return
    }
    fetch(`${apiUrl}api/intercom/offer`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        deviceId: callData?.deviceId,
        recipientId: userId,
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        if ('error' in json) {
          stopDialSound()
          setError(json.error)
        }
        //prePlay()
        call.current.callId = json.UUID
        call.current.intercomChannel = json.Channel
        centrifugeInit(call.current.intercomChannel)
        startDialSound()
        setStatus(CallStatus.CALLING)
      })
      .catch(() => {
        stopDialSound()
        setStatus(CallStatus.NO_AVAILABLE)
      })
  }

  const initCall = () => {
    call.current.centrifuge = new Centrifuge('wss://centrifugo.zesec.com/connection/websocket')

    call.current.rtcConnection = new RTCPeerConnection(rtcConfig)

    call.current.centrifuge.on('error', (e) => {
      devConsoleLog('centrifuge error', e)
    })

    // @ts-expect-error deprecated property, todo should be refactored
    call.current.rtcConnection.onaddstream = (evt: any) => {
      if (targetFrame.current) {
        setStatus(CallStatus.ANSWERED)
        targetFrame.current.srcObject = evt.stream
        targetFrame.current.volume = 1.0
      }
    }

    call.current.rtcConnection.ontrack = ({ track, streams: [stream] }) => {
      track.onunmute = () => {
        devConsoleLog('TRACK UNMUTED')
        setTargetFrameMode(false)
        if (stream.getVideoTracks().length > 0) {
          stopDialSound()
          // @ts-expect-error deprecated property, todo should be refactored
          call.current.rtcConnection.onaddstream(stream)
          if (targetFrame.current) {
            targetFrame.current.srcObject = stream
            targetFrame.current.volume = 1.0
          }
        } else {
          if (targetFrame.current) {
            targetFrame.current.srcObject = null
          }
        }
      }

      track.onmute = () => {
        setTargetFrameMode(true)
        devConsoleLog('TRACK MUTED')
      }
    }

    call.current.rtcConnection.onicecandidate = async (evt: any) => {
      if (evt.candidate && call.current.centrifuge) {
        await call.current.centrifuge.publish(call.current.intercomChannel, {
          type: 'candidate',
          sdp: evt.candidate,
        })
      }
    }

    call.current.rtcConnection.onicecandidateerror = (e: any) => {
      devConsoleLog('onicecandidateerror', e)
    }

    call.current.rtcConnection.onnegotiationneeded = () => {
      if (call.current.callClosed) {
        return
      }
      call.current.rtcConnection
        ?.setLocalDescription()
        .then(() => {
          call.current.centrifuge?.publish(call.current.intercomChannel, {
            type: 'offer',
            sdp: call.current.rtcConnection?.localDescription,
          })
        })
        .catch((e) => {
          devConsoleLog('rtc ld pn error, try no pn', e)
          !!call.current.rtcConnection &&
            call.current.rtcConnection
              .createOffer({ offerToReceiveAudio: true, offerToReceiveVideo: true })
              .then((desc: any) => {
                !!call.current.rtcConnection &&
                  call.current.rtcConnection
                    .setLocalDescription(desc)
                    .then(() => {
                      call.current.centrifuge?.publish(call.current.intercomChannel, {
                        type: 'offer',
                        sdp: desc,
                      })
                    })
                    .catch((e: any) => {
                      devConsoleLog('rtc ld no pn error', e)
                    })
              })
              .catch((e: any) => {
                devConsoleLog('rtc createoffer error', e)
              })
        })
    }

    call.current.rtcConnection.oniceconnectionstatechange = () => {
      if (
        call.current.rtcConnection &&
        call.current.rtcConnection.iceConnectionState === 'failed'
      ) {
        if (call.current.rtcConnection.restartIce) {
          call.current.rtcConnection.restartIce()
        } else {
          call.current.rtcConnection
            .createOffer({ iceRestart: true })
            .then((desc) => {
              call.current.rtcConnection
                ?.setLocalDescription(desc)
                .then(() => {
                  call.current.centrifuge?.publish(call.current.intercomChannel, {
                    type: 'offer',
                    sdp: desc,
                  })
                })
                .catch((e) => {
                  devConsoleLog('iceRestart ld error', e)
                })
            })
            .catch((e) => {
              devConsoleLog('iceRestart createoffer error', e)
            })
        }
      }
    }
  }

  const resetCall = () => {
    call.current.centrifuge = undefined
    call.current.rtcConnection = undefined
  }

  useEffect(() => {
    if (notification !== null) {
      showNotification(notification)
    }
  }, [notification])

  useEffect(() => {
    initCall()
    return () => {
      clearTimeout(callTimer.current)
    }
  }, [])

  useEffect(() => {
    if (prevStatus === CallStatus.NO_ANSWER && status === CallStatus.COMPLETED) {
      return
    }
    setCallStatus(status)
  }, [status])

  return {
    startCall,
    stopCall,
    stopStream,
  }
}

export default useCall
