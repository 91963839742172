import React, { useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { ErrorScreen, StartScreen } from '../screens'
import { useInit, useCurrentScreen } from '../hooks'
import { intercomErrorState } from '../store/error'
import { screenState } from '../store/screen'
import { Screen } from '../types'
import { useCookies } from 'react-cookie'

const App: React.FC = () => {
  const { dataLoaded } = useInit()
  const error = useRecoilValue(intercomErrorState)
  const currentScreen = useCurrentScreen()
  const setScreenStore = useSetRecoilState(screenState)
  const [cookies] = useCookies()

  useEffect(() => {
    if (dataLoaded) {
      const visitors = Object.keys(cookies).filter((cookie) => cookie.includes('__visitor__'))
      const devices = Object.keys(localStorage).filter((cookie) => cookie.includes('__visitor__'))
      devices.forEach((key) => {
        if (!cookies[key]) {
          localStorage.removeItem(key)
        }
      })
      setScreenStore(visitors.length ? { screen: Screen.WELCOME_BACK } : { screen: Screen.LIST })
    }
  }, [dataLoaded])

  return error ? (
    <ErrorScreen error={error} resetErrorBoundary={() => {}} />
  ) : dataLoaded ? (
    currentScreen
  ) : (
    <StartScreen />
  )
}

export default App
