import React from 'react'
import { IconProps } from './index'

const IconCall: React.FC<IconProps> = ({ size = 26, classes }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes ?? ''}
    >
      <path
        d="M11.3067 6.37557L13.8066 6.35269L13.8295 8.85259L11.3296 8.87547L11.3067 6.37557ZM11.3525 11.3754L13.8524 11.3525L13.921 18.8522L11.4211 18.8751L11.3525 11.3754ZM12.4995 0.114395C5.59977 0.177541 0.0512493 5.82856 0.114396 12.7283C0.177542 19.628 5.82856 25.1765 12.7283 25.1134C19.628 25.0502 25.1765 19.3992 25.1134 12.4995C25.0502 5.59977 19.3992 0.0512482 12.4995 0.114395ZM12.7054 22.6135C7.19312 22.6639 2.66474 18.2177 2.61429 12.7054C2.56384 7.19312 7.01009 2.66474 12.5224 2.61429C18.0346 2.56384 22.563 7.01009 22.6135 12.5224C22.6639 18.0346 18.2177 22.563 12.7054 22.6135Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default IconCall
