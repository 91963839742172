import React from 'react'
import { IconSearch } from '../icons'
import { SearchProps } from './index'
import { useTranslation } from 'react-i18next'

const Search: React.FC<SearchProps> = ({ toSearch }) => {
  const { t } = useTranslation()
  return (
    <div className="relative rounded-md w-full">
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <IconSearch size={18} />
      </div>
      <input
        className="block bg-gray-medium w-full h-8 leading-7 rounded-md border-0 pl-10 text-gray-900 placeholder:leading-7 focus-visible:outline-0 placeholder:text-gray-400 focus-visible:shadow-none focus:shadow-none text-sm"
        type="text"
        placeholder={t('searchByName')}
        onChange={(event) => toSearch(event.target.value)}
      />
    </div>
  )
}

export default Search
