import React from 'react'
import Modal, { Styles } from 'react-modal'

import type { ContentModalProps } from './index'
import { modalStyles } from './index'
import { Footer } from '../elements'

const ContentModal: React.FC<ContentModalProps> = ({ children }) => {
  return (
    <Modal
      isOpen={!!children}
      style={modalStyles as Styles}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      {children}
      <Footer />
    </Modal>
  )
}

export default ContentModal
