import React, { FC, useState, useCallback } from 'react'
import { BannerTop, DialPad, PersonBox, Search } from '../components/elements'
import { PersonType } from '../types'
import { intercomDoorState, intercomListState, intercomLocationState } from '../store/data'
import { useRecoilValue } from 'recoil'
import { ErrorScreen } from './index'
import { useTranslation } from 'react-i18next'
import { Layout } from '../components/common'

const ListScreen: FC = () => {
  const { t } = useTranslation()
  const listState = useRecoilValue(intercomListState)
  const locationState = useRecoilValue(intercomLocationState)
  const doorState = useRecoilValue(intercomDoorState)
  const [list, setList] = useState<PersonType[] | null>(listState)
  const toSearch = useCallback(
    (input: string) =>
      listState &&
      setList(listState.filter((p) => p.name.toLowerCase().includes(input.toLowerCase().trim()))),
    [list, setList]
  )

  return locationState && doorState ? (
    <Layout classes="bg-gray-100">
      <div className="bg-white w-full">
        <BannerTop location={locationState} door={doorState} />
      </div>
      <div className="px-4 pb-5 mt-5 ">
        <Search toSearch={toSearch} />
        <DialPad />
      </div>
      <div className="px-4 grow overflow-auto">
        {list &&
          list.map((p) => (
            <div key={p.id} className="mb-5">
              <PersonBox id={p.id} name={p.name} imageSmall={p.imageSmall} />
            </div>
          ))}
      </div>
    </Layout>
  ) : (
    <ErrorScreen error={t('error.disabled')} />
  )
}

export default ListScreen
