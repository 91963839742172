const sv = {
  translation: {
    answered: 'Svarat',
    busy: 'Upptagen',
    callAgain: 'Ring igen',
    calling: 'Ringer...',
    cancel: 'Avbryt',
    connecting: 'Ansluter...',
    doorCode: 'Dörrkod',
    end: 'Avsluta',
    enterCode: 'Ange kod för att öppna dörren',
    entering: '',
    footer: 'Powered by Zesec',
    hangUp: 'Lägg på',
    loading: 'Läser in',
    loadingIntercom: 'Laddar Zesec Porttelefoni',
    noAnswer: 'Inget svar',
    opened: 'Dörren är upplåst',
    searchByName: 'Sök på namn',
    welcome: {
      passport: 'Välkommen!',
      title: 'Välkommen tillbaka!',
      subtitle: 'Välj nedan hur du vill fortsätta',
      toList: 'Gå till besökslistan',
      goto: 'Gå till pass',
    },
    pass: {
      received: 'Du har fått passet - lägg på samtalet för att använda det',
      notDelivered:
        'Besökaren hittades inte eller har löpt ut. Be avsändaren att skicka passet på nytt.',
      expired:
        'Giltighetstiden för passet har upphört. För att få ett nytt pass vänligen skanna QR-koden och ring igen.',
      error:
        'Dörren låstes inte upp. Försök att trycka igen eller stäng pass och skanna QR-koden igen',
    },
    error: {
      disabled: 'Porttelefoni är inaktiverad på den här enheten',
      camera: 'Ingen kamera hittades',
      available: 'Otillgänglig',
      expired: 'Sessionen är avslutad. För att påbörja en ny, skanna QR-koden igen',
      fail: 'Koppling till enhet misslyckades, försök igen',
      code: 'Felaktig dörrkod. Vänligen ange korrekt kod för att låsa upp dörren',
      scan: 'Någonting gick fel, stäng fliken och skanna QR-koden igen',
      video: 'Your browser does not support video calls',
    },
  },
}

export default sv
