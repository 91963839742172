import { useSetRecoilState } from 'recoil'
import { intercomErrorState } from '../store/error'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'

const TIMEOUT = 600000
const DOMAIN = process.env.REACT_APP_DOMAIN

const useUtils = () => {
  const setError = useSetRecoilState(intercomErrorState)
  const [cookies, setCookies] = useCookies(['_gid'])
  const { t } = useTranslation()

  const cleanStorage = () => {
    localStorage.removeItem('sessionCall')
    localStorage.removeItem('sessionDate')
  }

  const getParsedCall = (str: string) => {
    try {
      return JSON.parse(atob(str))
    } catch (err) {
      setError(t('error.scan'))
    }
  }

  const parseCall = (call: string | null) => {
    const callFromStorage = localStorage?.getItem('sessionCall')
    const dateFromStorage = parseInt(localStorage?.getItem('sessionDate') ?? '', 10)
    if (call) {
      cleanStorage()
      return getParsedCall(call)
    }
    if (!callFromStorage) {
      setError(t('error.scan'))
      return
    }
    const currentDate = new Date().getTime()
    if (
      !Number.isNaN(dateFromStorage) &&
      currentDate > dateFromStorage &&
      currentDate - dateFromStorage < TIMEOUT
    ) {
      return getParsedCall(callFromStorage)
    } else {
      setError(t('error.expired'))
      return
    }
  }

  const initCookie = () => {
    const cookie = cookies._gid
    if (cookie) {
      return
    }
    let cookieValue = ''
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    for (let i = 0; i < 30; i++) {
      cookieValue += possible.charAt(Math.floor(Math.random() * possible.length))
    }
    setCookies('_gid', cookieValue, {
      domain: DOMAIN,
      maxAge: 86400,
      path: '/',
    })
  }

  const initStorage = (call: string | null) => {
    if (!call) return
    let existTimeout = TIMEOUT
    const currentDate = new Date().getTime()
    const currentCall = localStorage?.getItem('sessionCall')

    if (!currentCall) {
      localStorage.setItem('sessionCall', call)
      localStorage.setItem('sessionDate', currentDate.toString())
      window.history.pushState({}, document.title, '/?call=')
    }

    const date = parseInt(localStorage.getItem('sessionDate') ?? '', 10)
    if (!Number.isNaN(date) && currentDate > date && currentDate - date < existTimeout) {
      existTimeout = existTimeout - (currentDate - date) + 1000
    }

    setTimeout(() => {
      setError(t('error.expired'))
    }, existTimeout)
  }

  return {
    initCookie,
    initStorage,
    parseCall,
  }
}

export default useUtils
