import React from 'react'
import { ButtonWrapperProps } from '../index'

const ButtonWrapper: React.FC<ButtonWrapperProps> = ({ text, children }) => {
  return (
    <div className="flex flex-col justify-center items-center text-white mx-2">
      {children}
      <span className="text-sm mt-3.5">{text}</span>
    </div>
  )
}

export default ButtonWrapper
