import React from 'react'
import image from '../assets/images/zesec.png'
import { CustomImage } from '../components/elements'
import { useTranslation } from 'react-i18next'

const StartScreen: React.FC = () => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col justify-center items-center p-7 w-full">
      <div className="max-w-52 w-full">
        <CustomImage src={image} alt="Error image" />
      </div>
      <span className="text-xl font-medium mt-4">{t('loadingIntercom')}</span>
      <div className="relative flex justify-center items-center w-10 h-10 bg-gray-dark rounded-full mt-6 animate-spin">
        <div className="w-7 h-7 bg-white rounded-full">
          <div className="w-7 h-7 bg-white absolute left-1/2 top-1/2" />
        </div>
      </div>
    </div>
  )
}

export default StartScreen
