import React from 'react'
import { useRecoilValue } from 'recoil'
import { Screen } from '../types'
import { CallScreen, ListScreen, PassportScreen, StartScreen, WelcomeBackScreen } from '../screens'
import { screenState } from '../store/screen'

const useCurrentScreen = () => {
  const { screen, props } = useRecoilValue(screenState)
  switch (screen) {
    case Screen.CALL:
      return <CallScreen {...props} />
    case Screen.LIST:
      return <ListScreen />
    case Screen.PASSPORT:
      return (
        <PassportScreen
          uuid={props?.uuid}
          title={props?.title}
          imageSmall={props?.imageSmall}
          imageMedium={props?.imageMedium}
          imageLarge={props?.imageLarge}
          devices={props?.devices}
        />
      )
    case Screen.WELCOME_BACK:
      return <WelcomeBackScreen />
    default:
      return <StartScreen />
  }
}

export default useCurrentScreen
