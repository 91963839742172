import React from 'react'
import { BannerTopProps, CustomImage } from './index'
import defaultImage from '../../assets/images/door-default.png'

const BannerTop: React.FC<BannerTopProps> = ({
  location: { title },
  door: { imageMedium, title: doorTitle },
}) => {
  return (
    <div className="flex py-6 px-4">
      <div className="w-28 h-28 rounded-xl overflow-hidden mr-3">
        <CustomImage src={imageMedium} alt={title} defaultSrc={defaultImage} />
      </div>
      <div className="flex flex-col justify-end">
        <h2 className="text-lg font-bold mb-1">{title}</h2>
        <span className="text-sm">{doorTitle}</span>
      </div>
    </div>
  )
}

export default BannerTop
