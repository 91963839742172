import { useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { useTranslation } from 'react-i18next'
import {
  intercomListState,
  intercomLocationState,
  intercomDoorState,
  intercomCompanyState,
} from '../store/data'
import { accessTokenState, callDataState } from '../store/call'
import { intercomErrorState } from '../store/error'
import { useUtils } from './index'
import { devConsoleLog, isMobileDevice } from '../utils'

const apiUrl = process.env.REACT_APP_API_URL

const useInit = () => {
  const { t } = useTranslation()
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)
  const { initCookie, initStorage, parseCall } = useUtils()

  const setError = useSetRecoilState(intercomErrorState)

  const setToken = useSetRecoilState(accessTokenState)
  const setLocation = useSetRecoilState(intercomLocationState)
  const setList = useSetRecoilState(intercomListState)
  const setDoor = useSetRecoilState(intercomDoorState)
  const setCompany = useSetRecoilState(intercomCompanyState)
  const setCallData = useSetRecoilState(callDataState)

  useEffect(() => {
    const fullUrl = window?.location?.href
    const call = fullUrl ? new URL(fullUrl).searchParams.get('call') : null

    if (!isMobileDevice() || call === null) {
      window.location.href = 'https://zesec.com'
    }

    const callParsed = parseCall(call)
    if (callParsed) {
      setCallData(callParsed)

      initStorage(call)
      initCookie()

      fetch(`${apiUrl}api/intercom/token`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then((data) => {
          const token = data.AccessToken
          setToken(token)
          token &&
            callParsed &&
            fetch(`${apiUrl}api/intercom/device/${callParsed.deviceId}/recipients`, {
              method: 'GET',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`,
              },
            })
              .then((res) => res.json())
              .then((data) => {
                devConsoleLog(data)
                data?.Company &&
                  setCompany({
                    id: data.Company.Id,
                    title: data.Company.Name,
                    imageSmall: data.Company.SmallImageUrl,
                    imageMedium: data.Company.MediumImageUrl,
                    imageLarge: data.Company.LargeImageUrl,
                  })
                data?.Device &&
                  setDoor({
                    id: data.Device.Id,
                    title: data.Device.Name,
                    imageSmall: data.Device.SmallImageUrl,
                    imageMedium: data.Device.MediumImageUrl,
                    imageLarge: data.Device.LargeImageUrl,
                  })
                data?.Location &&
                  setLocation({
                    title: data.Location.Name,
                    imageSmall: data.Location.SmallImageUrl,
                    imageMedium: data.Location.MediumImageUrl,
                    imageLarge: data.Location.LargeImageUrl,
                    address: data.Location.Address,
                  })
                data?.Recipients &&
                  Array.isArray(data.Recipients) &&
                  setList(
                    data.Recipients.map((p: any) => ({
                      id: p.Id,
                      imageSmall: p.SmallImageUrl,
                      imageMedium: p.MediumImageUrl,
                      imageLarge: p.LargeImageUrl,
                      name: p.Name,
                    }))
                  )
              })
              .catch(() => {
                setError(t('error.scan'))
              })
              .finally(() => setDataLoaded(true))
        })
        .catch(() => {
          setDataLoaded(true)
          setError(t('error.scan'))
        })
    }
  }, [])

  return { dataLoaded }
}

export default useInit
