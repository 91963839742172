import React, { FC, useEffect, useState } from 'react'
import { Notification, NotificationProps, type NotificationsListProps } from './index'

const NotificationsList: FC<NotificationsListProps> = ({ list }) => {
  const [notifications, setNotifications] = useState<NotificationProps[]>(list)
  useEffect(() => {
    setNotifications(list)
  }, [list])
  return (
    <div className="fixed top-4 left-4 right-4 z-50">
      {notifications.map((n) => (
        <Notification
          key={n.id}
          type={n.type}
          text={n.text}
          classes={`${n.classes} mb-4`}
          withTimer
        />
      ))}
    </div>
  )
}

export default NotificationsList
