import React from 'react'
import { IconProps } from './index'

const IconKey: React.FC<IconProps> = ({ size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M21.2324 40.644C25.3078 40.644 28.6116 37.3402 28.6116 33.2648C28.6116 29.1894 25.3078 25.8856 21.2324 25.8856C17.157 25.8856 13.8532 29.1894 13.8532 33.2648C13.8532 37.3402 17.157 40.644 21.2324 40.644Z"
          stroke="currentColor"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M28.8352 5.98425H21.2324V25.8856"
          stroke="currentColor"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
        <path
          d="M27.0463 13.3634H21.2324"
          stroke="currentColor"
          strokeWidth="4"
          strokeMiterlimit="10"
        />
      </g>
    </svg>
  )
}

export default IconKey
