const en = {
  translation: {
    answered: 'Answered',
    busy: 'Busy',
    callAgain: 'Call back again',
    calling: 'Calling...',
    cancel: 'Cancel',
    connecting: 'Connecting...',
    doorCode: 'Door code',
    end: 'End',
    enterCode: 'Press the code to open the door',
    entering: 'Entrance gate',
    footer: 'Powered by Zesec',
    hangUp: 'Hang up',
    loading: 'Loading',
    loadingIntercom: 'Loading Zesec Intercom...',
    noAnswer: 'No answer',
    opened: 'The door is opened',
    searchByName: 'Search name',
    welcome: {
      passport: 'Welcome!',
      title: 'Welcome back!',
      subtitle: 'Please select how you want to proceed',
      toList: 'Go to visitor list',
      goto: 'Go to pass',
    },
    pass: {
      received: 'Pass received - hangup the call to access the pass',
      notDelivered:
        'The visitor was not found or expired. Please, ask the sender to send the passport again.',
      expired:
        'The passport has expired. To obtain a new one, please scan the QR-code and make the call again.',
      error: 'Door didn’t unlock. Try pressing again or close pass and scan QR-code again',
    },
    error: {
      disabled: 'Intercom is disabled for this device',
      camera: 'No camera found',
      available: 'Not available',
      expired: 'The session has expired. To start a new one, please scan the QR-code again',
      fail: 'The connection to the device failed, please try again',
      code: 'Incorrect door code. Please enter the correct code to unlock the door',
      scan: 'Something went wrong, close this tab and re-scan the QR code',
      video: 'Your browser does not support video calls',
    },
  },
}

export default en
