import App from './components/App'
import { RecoilRoot } from 'recoil'
import './assets/styles/index.css'
import ReactDOM from 'react-dom/client'
import React from 'react'
import './translation/i18n'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorScreen } from './screens'
import Modal from 'react-modal'

const rootElement = document.getElementById('root') as HTMLElement
const root = ReactDOM.createRoot(rootElement)
Modal.setAppElement(rootElement)

root.render(
  <RecoilRoot>
    <ErrorBoundary FallbackComponent={ErrorScreen}>
      <App />
    </ErrorBoundary>
  </RecoilRoot>
)
