import React from 'react'
import Modal, { Styles } from 'react-modal'
import { modalStyles } from './index'
import { CustomImage, Footer } from '../elements'
import imageLogo from '../../assets/images/zesec-dark.png'
import { useTranslation } from 'react-i18next'
import { useRecoilValue } from 'recoil'
import { intercomCompanyState } from '../../store/data'

const LoadingModal = () => {
  const { imageMedium } = useRecoilValue(intercomCompanyState)
  const { t } = useTranslation()
  return (
    <Modal
      isOpen
      style={modalStyles as Styles}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <div className="w-56 h-56 relative m-auto mt-6">
        <div className="w-56 h-56 absolute left-0 top-0 right-0 bottom-0 m-auto border-8 border-white border-t-blue rounded-full animate-spin" />
        <div className="w-36 h-36 absolute left-0 top-0 right-0 bottom-0 m-auto z-10 rounded-full overflow-hidden">
          <CustomImage src={imageMedium} alt="Loading" defaultSrc={imageLogo} />
        </div>
      </div>
      <div className="font-bold my-4 mx-6 text-center grow">{t('connecting')}</div>
      <Footer />
    </Modal>
  )
}

export default LoadingModal
