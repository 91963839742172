/* todo should be refactored */

export const isWebRTCSupported = () => {
  let webRTCSupported = false
  const techs = [
    'RTCPeerConnection',
    'webkitRTCPeerConnection',
    'mozRTCPeerConnection',
    'RTCIceGatherer',
  ]
  techs.forEach((item) => {
    if (webRTCSupported) {
      return
    }

    if (item in window) {
      webRTCSupported = true
    }
  })

  if (window.navigator.userAgent.includes('Edge')) {
    return false
  }

  return webRTCSupported
}

export const parseJwt = (token: string) => {
  if (token == null) return JSON.parse('{}')

  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export const isMobileDevice = () => {
  return (
    typeof window.orientation !== 'undefined' ||
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ||
    window.innerWidth < 700
  )
}

export const devConsoleLog = (...params: any[]) =>
  process.env.NODE_ENV === 'development' && console.log(...params)
