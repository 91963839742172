import React from 'react'
import { ButtonProps } from '../index'

const Button: React.FC<ButtonProps> = ({ classes = '', icon, onClick }) => {
  return (
    <button
      className={`flex items-center justify-center rounded-full p-2 ${classes}`}
      onClick={onClick}
    >
      {icon}
    </button>
  )
}

export default Button
