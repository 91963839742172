import React, { useCallback } from 'react'
import { ButtonGoTo, CustomImage } from '../components/elements'
import { useSetRecoilState } from 'recoil'
import { screenState } from '../store/screen'
import image from '../assets/images/welcome.png'
import { PassportType, Screen } from '../types'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { Layout } from '../components/common'

const WelcomeBackScreen = () => {
  const { t } = useTranslation()
  const setScreen = useSetRecoilState(screenState)
  const goToList = useCallback(() => setScreen({ screen: Screen.LIST }), [])
  const [cookies] = useCookies()
  const visitors = Object.keys(cookies).filter((key) => key.includes('__visitor__'))
  visitors.sort((prev, next) => {
    const a = parseInt(prev.split('__')[0])
    const b = parseInt(next.split('__')[0])
    return b - a
  })
  const passports = visitors.map((key) => ({
    uuid: cookies[key],
    ...JSON.parse(localStorage.getItem(key) ?? '{}'),
    //devices: JSON.parse(localStorage.getItem(key) ?? '[]'),
  }))
  const goToPass = useCallback(
    (passport: PassportType) => setScreen({ screen: Screen.PASSPORT, props: { ...passport } }),
    []
  )

  return (
    <Layout
      classes="justify-start bg-gray-light px-4 pt-10 pb-16"
      footerClasses="shrink-0 fixed bottom-0 left-0 w-full"
    >
      <h2 className="font-bold text-2xl text-dark px-4 shrink-0">{t('welcome.title')}</h2>
      <p className="text-lg text-dark font-medium px-4 shrink-0">{t('welcome.subtitle')}</p>
      <div className="my-3 px-4">
        <CustomImage src={image} alt={t('welcome.title')} />
      </div>
      <div className="px-4 overflow-auto grow">
        <ButtonGoTo text={t('welcome.toList')} onClick={goToList} />
        {passports.map((pass: PassportType) => (
          <ButtonGoTo
            key={pass.uuid}
            classes="mt-5 text-left"
            text={`${t('welcome.goto')} ${pass.title}`}
            onClick={() => goToPass(pass)}
          />
        ))}
      </div>
    </Layout>
  )
}

export default WelcomeBackScreen
