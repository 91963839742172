export type CallDataType = {
  deviceId: string
  sessionId: string
}

export interface PersonType {
  id: number
  name: string
  imageSmall?: string
  imageMedium?: string
  imageLarge?: string
}

export interface LocationType {
  title: string
  address: string
  imageSmall?: string
  imageMedium?: string
  imageLarge?: string
}

export interface DoorType {
  id: number
  title: string
  imageSmall?: string
  imageMedium?: string
  imageLarge?: string
}

export interface CompanyType {
  id: number
  title: string
  imageSmall?: string
  imageMedium?: string
  imageLarge?: string
}

export enum Screen {
  LIST = 'screen_list',
  CALL = 'screen_call',
  START = 'screen_start',
  WELCOME_BACK = 'screen_welcomeBack',
  PASSPORT = 'screen_passport',
}

export enum CallStatus {
  INITIAL = 'initial',
  CALLING = 'calling',
  NO_ANSWER = 'noAnswer',
  BUSY = 'busy',
  NO_AVAILABLE = 'noAvailable',
  ANSWERED = 'answered',
  COMPLETED = 'completed',
}

export type PassportType = {
  uuid: string
  title: string
  imageSmall: string
  imageMedium: string
  imageLarge: string
  devices: any[]
}
