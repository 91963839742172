import React from 'react'
import Modal, { Styles } from 'react-modal'
import { Player } from '@lottiefiles/react-lottie-player'

import type { StateModalProps } from './index'
import { modalStyles } from './index'
import { Footer } from '../elements'

const ErrorModal: React.FC<StateModalProps> = ({ text }) => {
  return (
    <Modal
      isOpen={!!text}
      style={modalStyles as Styles}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <div className="mt-8">
        <Player
          autoplay
          keepLastFrame
          speed={0.8}
          src="https://lottie.host/eae2758c-1d58-49a7-8ca3-645bf1e69180/Nn8tdIx21T.json"
          style={{ height: '200px', width: '200px' }}
        />
      </div>
      <div className="font-bold my-4 mx-6 text-center grow">{text}</div>
      <Footer />
    </Modal>
  )
}

export default ErrorModal
