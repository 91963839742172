import { atom, RecoilState } from 'recoil'
import { CallDataType } from '../types'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const callDataState: RecoilState<CallDataType | null> = atom({
  key: 'call_data',
  default: null,
})

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export const accessTokenState: RecoilState<string | null> = atom({
  key: 'intercom_access_token',
  default: null,
})
