import React from 'react'
import { IconProps } from './index'

const IconArrowRight: React.FC<IconProps> = ({ size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M15.0667 6.26667C14.5333 6.8 14.5333 7.6 15.0667 8.13333L21.6 14.6667H6.66668C5.86668 14.6667 5.33334 15.2 5.33334 16C5.33334 16.8 5.86668 17.3333 6.66668 17.3333H21.6L15.0667 23.8667C14.5333 24.4 14.5333 25.2 15.0667 25.7333C15.6 26.2667 16.4 26.2667 16.9333 25.7333L26.6667 16L16.9333 6.26667C16.4 5.73333 15.6 5.73333 15.0667 6.26667Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default IconArrowRight
