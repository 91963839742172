import React, { ReactNode, useEffect, useState } from 'react'
import { IconInfo } from '../icons'
import type { NotificationProps } from './index'

const Notification: React.FC<NotificationProps> = ({ type, text, small, classes, withTimer }) => {
  const [data, setData] = useState<{ classes: string; icon: ReactNode | null } | null>(null)
  const [animationClasses, setAnimationClasses] = useState<string>(
    withTimer ? '-top-20 opacity-0' : 'top-4 opacity-100'
  )
  const persistClasses =
    'bg-white p-4 rounded-xl shadow flex items-start justify-start transition-all duration-500'
  let startTimeout: string | number | NodeJS.Timeout | undefined,
    finishTimeout: string | number | NodeJS.Timeout | undefined,
    endTimeout: string | number | NodeJS.Timeout | undefined
  useEffect(() => {
    switch (type) {
      case 'error':
        setData({
          classes: 'text-error',
          icon: <IconInfo size={small ? 16 : 26} />,
        })
        break
      case 'info':
        setData({
          classes: 'text-primary',
          icon: <IconInfo size={small ? 16 : 26} />,
        })
        break
      default:
        setData({
          classes: 'text-primary',
          icon: <IconInfo size={small ? 16 : 26} />,
        })
    }
    if (withTimer) {
      startTimeout = setTimeout(() => setAnimationClasses('top-4 opacity-100'), 100)
      finishTimeout = setTimeout(() => setAnimationClasses('-top-20 opacity-0'), 4100)
      endTimeout = setTimeout(() => setData(null), 4600)
    }
    return () => {
      if (withTimer) {
        clearTimeout(startTimeout)
        clearTimeout(finishTimeout)
        clearTimeout(endTimeout)
      }
    }
  }, [])
  return data ? (
    <div
      className={`${persistClasses} ${small ? 'text-xs' : 'text-base'} ${data.classes} ${classes ?? ''} ${animationClasses}`}
    >
      <span className={small ? 'mr-2' : 'mr-3'}>{data.icon}</span>
      {text}
    </div>
  ) : null
}

export default Notification
