import React from 'react'
import { PersonType, Screen } from '../../types'
import { Button, CustomImage } from './index'
import { IconCall, IconPerson } from '../icons'
import { screenState } from '../../store/screen'
import { useSetRecoilState } from 'recoil'

const PersonBox: React.FC<PersonType> = ({ name, imageSmall, id }) => {
  const setScreen = useSetRecoilState(screenState)
  return (
    <div className="w-full flex items-center justify-between rounded-2xl bg-white p-3 shadow-lg">
      <div className="w-11 h-11 overflow-hidden rounded-full">
        <CustomImage
          src={imageSmall}
          alt={name}
          defaultIcon={
            <div
              className={
                'w-11 h-11 flex items-center justify-center rounded-full bg-gray-400 text-white'
              }
            >
              <IconPerson size={28} />
            </div>
          }
        />
      </div>
      <div className="grow ml-3">{name}</div>
      <Button
        icon={<IconCall size={26} />}
        onClick={() => setScreen({ screen: Screen.CALL, props: { userId: id } })}
        classes="bg-green-500 text-white"
      />
    </div>
  )
}
export default PersonBox
