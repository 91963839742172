import React from 'react'

const Footer: React.FC<{ classes?: string }> = ({ classes }) => {
  return (
    <footer className={`flex justify-center items-end p-4 text-xs ${classes ?? ''}`}>
      Powered by Zesec
    </footer>
  )
}

export default Footer
