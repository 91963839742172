import { ReactNode } from 'react'

export { default as SuccessModal } from './SuccessModal'
export { default as LoadingModal } from './LoadingModal'
export { default as ErrorModal } from './ErrorModal'
export { default as ContentModal } from './ContentModal'

export type ContentModalProps = {
  children: ReactNode
}

export type StateModalProps = {
  text: string
}

export const modalStyles = {
  overlay: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 51,
    backgroundColor: 'rgba(68, 68, 68, .4)',
    padding: '15px 10px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 51,
    width: '100%',
    paddingBottom: '5px',
    border: 'none',
    borderRadius: '15px',
    backgroundColor: '#fff',
    overflow: 'hidden',
    minWidth: '260px',
    maxWidth: '320px',
    minHeight: '420px',
    margin: '0 auto',
  },
}
