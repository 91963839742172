import React, { useCallback } from 'react'
import { ButtonFull, ButtonGoToProps } from '../index'
import { IconArrowRight } from '../../icons'

const ButtonGoTo: React.FC<ButtonGoToProps> = ({ classes, text, onClick }) => {
  const clicked = useCallback(onClick, [])
  return (
    <ButtonFull
      classes={classes}
      text={text}
      icon={<IconArrowRight />}
      color={{ icon: '#e9511d', text: '#444' }}
      onClick={clicked}
    />
  )
}

export default ButtonGoTo
