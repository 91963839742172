import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Button, ButtonWrapper, CustomImage } from '../components/elements'
import { StartScreenProps } from './index'
import { IconCallEnd, IconClose, IconPerson } from '../components/icons'
import { intercomDoorState, intercomListState } from '../store/data'
import { useCall } from '../hooks'
import { useTranslation } from 'react-i18next'
import { CallStatus } from '../types'
import { devConsoleLog } from '../utils'
import { Layout, NotificationProps } from '../components/common'
import { notificationsState } from '../store/notifications'
import defaultImage from '../assets/images/door-default.png'

const StartScreen: React.FC<StartScreenProps> = ({ userId }) => {
  const list = useRecoilValue(intercomListState)
  const door = useRecoilValue(intercomDoorState)
  const [status, setCallStatus] = useState<CallStatus>(CallStatus.INITIAL)
  const [targetCameraOff, setTargetCameraOff] = useState<boolean>(true)
  const [notifications, setNotifications] = useRecoilState(notificationsState)

  const showNotification = (newOne: NotificationProps) => {
    setNotifications([newOne].concat(notifications))
  }

  const { t } = useTranslation()

  const userFrame = useRef<HTMLVideoElement | null>(null)
  const targetFrame = useRef<HTMLVideoElement | null>(null)
  const person = list?.find((i) => i.id === userId)
  const personImage = person?.imageMedium || person?.imageSmall
  const doorImage = door?.imageLarge || door?.imageMedium || door?.imageSmall

  const setTargetFrameMode = (value: boolean) => {
    setTargetCameraOff(
      value &&
        (status === CallStatus.INITIAL ||
          status === CallStatus.CALLING ||
          status === CallStatus.NO_AVAILABLE ||
          status === CallStatus.BUSY ||
          status === CallStatus.NO_ANSWER)
    )
  }

  const getTitle = () => {
    switch (status) {
      case CallStatus.CALLING:
        return t('calling')
      case CallStatus.NO_ANSWER:
        return t('noAnswer')
      case CallStatus.BUSY:
        return t('busy')
      case CallStatus.NO_AVAILABLE:
        return t('error.available')
      case CallStatus.ANSWERED:
        return t('answered')
      default:
        return ''
    }
  }

  const { startCall, stopCall } = useCall({
    setTargetFrameMode,
    targetFrame,
    userId,
    userFrame,
    setCallStatus,
    showNotification,
  })

  const controlsClass = () => {
    switch (status) {
      case CallStatus.INITIAL:
      case CallStatus.CALLING:
        return 'justify-center mb-14'
      case CallStatus.NO_AVAILABLE:
      case CallStatus.NO_ANSWER:
      case CallStatus.BUSY:
        return 'justify-center mb-14'
      case CallStatus.ANSWERED:
        return 'justify-end mb-14'
      default:
        return 'justify-start'
    }
  }

  const end = () => {
    window.location.reload()
  }

  const stop = () => {
    stopCall()
    end()
  }

  useEffect(() => {
    const letsGo = async () => {
      await startCall()
    }
    letsGo().catch((e) => devConsoleLog('error mon start', e))
  }, [])

  useEffect(() => {
    devConsoleLog('CALL SCREEN status', status)
    status === CallStatus.COMPLETED && end()
  }, [status])

  useEffect(() => {
    devConsoleLog('targetCameraOff', targetCameraOff)
  }, [targetCameraOff])

  return (
    <Layout
      classes="justify-center items-center px-2 bg-black"
      footerClasses="relative z-30 text-white"
    >
      <div className="w-full h-full absolute z-10 top-0 left-0">
        <CustomImage src={doorImage} defaultSrc={defaultImage} alt="Door image" classes="blur-sm" />
      </div>
      <div className="w-full h-full absolute z-10 top-0 left-0">
        <video
          ref={targetFrame}
          autoPlay
          playsInline
          className={`w-full h-full object-cover ${targetCameraOff ? 'hidden' : 'block'}`}
        ></video>
      </div>

      <div className="flex flex-col justify-start items-center w-full">
        <div
          className={`relative z-20 text-white font-bold text-2xl ${status === CallStatus.ANSWERED ? 'order-1 mt-[20%]' : 'order-2'}`}
        >
          {person?.name}
        </div>

        <div
          className={`relative z-20 text-white font-bold text-4xl my-2 ${status === CallStatus.ANSWERED ? 'order-2' : 'order-1 mt-[20%]'}`}
        >
          {getTitle()}
        </div>
      </div>

      <div className="relative z-20 w-40 h-40 rounded-full overflow-hidden mt-8 mb-2">
        {(status !== CallStatus.ANSWERED || targetCameraOff) && (
          <CustomImage
            src={personImage}
            alt={person?.name ?? 'The person photo'}
            defaultIcon={
              <div
                className={
                  'w-40 h-40 flex items-center justify-center rounded-full bg-gray-400 text-white'
                }
              >
                <IconPerson size={150} />
              </div>
            }
          />
        )}
      </div>

      <div className={`flex w-full items-end relative z-20 grow mt-2 ${controlsClass()}`}>
        {status === CallStatus.CALLING && (
          <ButtonWrapper text={t('cancel')}>
            <Button classes="bg-error w-14 h-14" icon={<IconCallEnd size={32} />} onClick={stop} />
          </ButtonWrapper>
        )}
        {(status === CallStatus.NO_ANSWER ||
          status === CallStatus.NO_AVAILABLE ||
          status === CallStatus.BUSY) && (
          <>
            <ButtonWrapper text={t('cancel')}>
              <Button classes="bg-error w-14 h-14" icon={<IconClose size={24} />} onClick={end} />
            </ButtonWrapper>
            {/*<ButtonWrapper text={t('callAgain')}>
              <Button
                classes="bg-access w-14 h-14"
                icon={<IconCall size={32} />}
                onClick={startCall}
              />
            </ButtonWrapper>*/}
          </>
        )}
        {status === CallStatus.ANSWERED && (
          <ButtonWrapper text={t('hangUp')}>
            <Button classes="bg-error w-14 h-14" icon={<IconCallEnd size={32} />} onClick={stop} />
          </ButtonWrapper>
        )}
        <video
          className={`w-36 h-48 object-cover ${status === CallStatus.ANSWERED ? 'block' : 'hidden'}`}
          ref={userFrame}
          autoPlay
          playsInline
          muted
        ></video>
      </div>
    </Layout>
  )
}

export default StartScreen
