import React, { FC } from 'react'
import { useRecoilValue } from 'recoil'
import { LayoutProps, NotificationsList } from './index'
import { Footer } from '../elements'
import { notificationsState } from '../../store/notifications'

const Layout: FC<LayoutProps> = ({ classes, footerClasses, children }) => {
  const notifications = useRecoilValue(notificationsState)
  return (
    <div className={`flex flex-col w-full h-[100dvh] overflow-hidden ${classes ?? ''}`}>
      <NotificationsList list={notifications} />
      {children}
      <Footer classes={footerClasses ?? ''} />
    </div>
  )
}

export default Layout
