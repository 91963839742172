import React, { FC, useState } from 'react'
import { type ImageProps } from './index'
import defaultImage from '../../assets/images/error.png'

const CustomImage: FC<ImageProps> = ({
  alt,
  classes,
  src,
  defaultSrc,
  defaultIcon,
  bgColor,
  objectFit,
}) => {
  const [image, setImage] = useState(src)
  const [error, setError] = useState<boolean>(false)
  const processError = () => {
    setImage(defaultSrc ?? defaultImage)
    setError(true)
  }

  const bgColorClass = bgColor ? `bg-[${bgColor}]` : ''

  React.useEffect(() => {
    const img: HTMLImageElement = new Image()
    img.src = String(src)
    img.addEventListener('error', processError)
    return () => {
      img.removeEventListener('error', processError)
    }
  })
  return error && defaultIcon ? (
    defaultIcon
  ) : (
    <img
      className={`w-full h-full object-center ${objectFit ? 'object-contain' : 'object-cover'} ${classes ?? ''} ${bgColorClass}`}
      src={image}
      alt={alt ?? 'Some alt text'}
    />
  )
}

export default CustomImage
