import React from 'react'
import { IconInfo } from '../components/icons'
import image from '../assets/images/error.png'
import { CustomImage, Footer } from '../components/elements'
import { ErrorScreenProps } from './index'

const ErrorScreen: React.FC<ErrorScreenProps> = ({ error }) => {
  return (
    <div className="flex flex-col justify-center items-center w-full p-7">
      <div className="flex items-end justify-start">
        <div className="flex items-start justify-start">
          <div className="shrink mr-3 mt-1">
            <IconInfo size={22} />
          </div>
          <span className="text-2xl font-medium">{error.message ? error.message : error}</span>
        </div>
      </div>
      <div className="max-w-52 w-full">
        <CustomImage src={image} alt="Error image" />
      </div>
      <Footer />
    </div>
  )
}

export default ErrorScreen
